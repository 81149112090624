import axios from 'axios';
import { apiSecret1, apiSecret2, apiSecureHeader } from './constants';

export const formPublish = async (formData, onSuccess, onError) => {
    try {
      formData.append('secret1', apiSecret1);
      formData.append('secret2', apiSecret2);
        // console.log(formData.get('f_name'))
        // formData.append("artist_id",'')
      const response = await axios.post(
        'https://hallelujahofficial.com/api/godcast/user/update', 
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
            'secure': apiSecureHeader,
            'is_admin': true,
          }
        }
      );
  
      if (onSuccess) {
        onSuccess(response.data);
      }
        return response.data;
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || "Internal Server Error. Please try again after some time.";
      return Promise.reject({ error: errorMessage });
    }
};


export const filePublish = async (file, onUploadProgress, onSuccess, onError) => {
    const formData = new FormData();
    formData.append('file', file);
  
    try {
      const response = await axios.post(
        'https://hallelujahofficial.com/api/users/dp/update', // Update with your actual endpoint
        formData,
        {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'multipart/form-data',
            'secure': apiSecureHeader,
            'is_admin': true,
          },
          onUploadProgress: (progressEvent) => {
            if (onUploadProgress) {
              onUploadProgress(progressEvent);
            }
          }
        }
      );
  
      if (onSuccess) {
        onSuccess(response.data);
      }
        return response.data;
    } catch (error) {
      if (onError) {
        onError(error);
      }
  
      // console.error('Error uploading file:', error);
      throw new Error('Failed to upload file');
    }
};
  
  

export const getUser = async () => {
  try {
    const response = await axios.get('https://hallelujahofficial.com/api/godcast/user/get', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
        'Content-Type': 'application/json',
        'secure': apiSecureHeader, // Assuming apiSecureHeader is defined elsewhere
        'is_admin': true, // Assuming 'is_admin' is relevant to the API request
      },
    });

    // Check for successful status code (2xx range)
    if (response.status < 200 || response.status >= 300) {
      throw new Error(`API request failed with status code: ${response.status}`);
    }

    return response.data;

  } catch (error) {
    console.error('Error fetching user data:', error);
    
    // Handle specific errors based on their nature (optional)
    if (error.response && error.response.data) {
      // Extract error message from response data
      const errorMessage = error.response.data.message || 'Unknown error';
      throw new Error(errorMessage);
    }

    // If specific error handling isn't needed, throw a generic error
    throw new Error('Failed to fetch user data');
  }
};