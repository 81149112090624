import React, { useState, useRef, useEffect } from "react";
import Sidebar from "../../components/sidebar/Sidebar";
import { useNavigate } from "react-router-dom";
import "./Record.scss";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import {
  Tabs,
  Tab,
  Box,
  TextField,
  Typography,
  Divider,
  CircularProgress,
} from "@mui/material";
import MicIcon from "@mui/icons-material/Mic";
import axios from "axios";
import { Toaster, toast } from "react-hot-toast";
import {
  apiSecret1,
  apiSecret2,
  apiSecureHeader,
} from "../../helper/constants";
import RecordItem from "./RecordItem";
import BackButton from "../../components/buttons/BackButton";
import { AddToSegments, deleteLibraryContent } from "../../helper/musicHelper";
import Modal from "react-modal";
import checked from "../../Assets/checked.png";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import AudioAnalyser from "react-audio-analyser";
import { Button, Input } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { Field } from "formik";

Modal.setAppElement("#root");

const Record = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("record");
  const [recording, setRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [audioURL, setAudioURL] = useState(null);
  const [recordedAudios, setRecordedAudios] = useState([]);
  const [audioName, setAudioName] = useState("");
  const [library, setLibrary] = useState([]);
  const [backgroundMusics, setBackgroundMusics] = useState([]);
  const [interludes, setInterludes] = useState([]);
  const [sounds, setSounds] = useState([]);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentlyPlaying, setCurrentlyPlaying] = useState(null);
  const open = Boolean(anchorEl);
  const [newRecording, setNewRecording] = useState({ name: "", file: null });
  const [selectedMusic, setSelectedMusic] = useState(null);
  const [timer, setTimer] = useState(0);
  const timerRef = useRef(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [renameIsOpen, setRenameIsOpen] = useState(false);
  const [nameIsOpen, setnameIsOpen] = useState(false);
  const [newTitle, setNewTitle] = useState("");
  const [newRecordingTitle, setNewRecordingTitle] = useState("");
  const [audioStatus, setAudioStatus] = useState("");
  const [audioSrc, setAudioSrc] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const fileInputRef = useRef(null);
  const [addIsOpen, setAddIsOpen] = useState(false);
  const [segments, setSegments] = useState([]);
  const recordingChunksRef = useRef([]);
  const isCancelledRef = useRef(false); // Ref to track if recording is cancelled

  // const [formData, setFormData] = useState(new FormData());

  const getAuthHeaders = () => ({
    secure: apiSecureHeader,
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    is_admin: true,
  });

  useEffect(() => {
    const fetchSegments = async () => {
      try {
        const response = await axios.get(
          "https://hallelujahofficial.com/api/godcast/segments",
          {
            headers: {
              secure: apiSecureHeader,
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              is_admin: true,
            },
          }
        );
        setSegments(response.data);
        setLoading(false);
      } catch (error) {
        toast.error("Failed to fetch segments");
        setLoading(false);
      }
    };

    fetchSegments();
  }, []);

  // console.log(selectedMusic);

  const handleAddInterlude = async () => {
    if (!selectedMusic) return;

    // Check for name conflicts
    const isDuplicateTitle = segments.some(
      (segment) => segment.name === selectedMusic.name
    );
    if (isDuplicateTitle) {
      toast.error(
        "Segment with this name already exists. Please choose a different music."
      );
      return;
    }

    try {
      await axios.post(
        `https://hallelujahofficial.com/api/godcast/segments/interlude/add`,
        {
          name: selectedMusic.name,
          interlude_id: selectedMusic.id,
        },
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );

      // Fetch the updated segments list after adding the new segment
      const response = await axios.get(
        "https://hallelujahofficial.com/api/godcast/segments",
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );
      setSegments(response.data);
      toast.success("Added to segment successfully");
    } catch (error) {
      toast.error("Failed to add segment");
    } finally {
      closeAdd();
    }
  };

  const handleAddSound = async () => {
    if (!selectedMusic) return;

    // Check for name conflicts
    const isDuplicateTitle = segments.some(
      (segment) => segment.name === selectedMusic.name
    );
    if (isDuplicateTitle) {
      toast.error(
        "Segment with this name already exists. Please choose a different music."
      );
      return;
    }

    try {
      await axios.post(
        `https://hallelujahofficial.com/api/godcast/segments/sound/add`,
        {
          name: selectedMusic.name,
          sound_id: selectedMusic.id,
        },
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );

      // Fetch the updated segments list after adding the new segment
      const response = await axios.get(
        "https://hallelujahofficial.com/api/godcast/segments",
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );
      setSegments(response.data);
      toast.success("Added to segment successfully");
    } catch (error) {
      toast.error("Failed to add segment");
    } finally {
      closeAdd();
    }
  };

  const handleAddLibrary = async () => {
    if (!selectedMusic) return;

    // Check for name conflicts
    const isDuplicateTitle = segments.some(
      (segment) => segment.name === selectedMusic.title
    );
    if (isDuplicateTitle) {
      toast.error(
        "Segment with this name already exists. Please choose a different music."
      );
      return;
    }

    try {
      await axios.post(
        `https://hallelujahofficial.com/api/godcast/segments/library/add`,
        {
          name: selectedMusic.title,
          library_id: selectedMusic.id,
        },
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );

      // Fetch the updated segments list after adding the new segment
      const response = await axios.get(
        "https://hallelujahofficial.com/api/godcast/segments",
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );
      setSegments(response.data);
      toast.success("Added to segment successfully");
    } catch (error) {
      toast.error("Failed to rename segment");
    } finally {
      closeAdd();
    }
  };

  const handleDelete = async () => {
    if (!selectedMusic) return;

    try {
      // console.log(selectedMusic.id);
      await deleteLibraryContent(selectedMusic.id); // API call to delete music

      // Remove the deleted music from the appropriate list
      setLibrary((prevList) =>
        prevList.filter((music) => music.id !== selectedMusic.id)
      );

      toast.success("Music deleted successfully");
    } catch (error) {
      // console.error('Error deleting music:', error);
      toast.error("Failed to delete music");
    } finally {
      closeConfirmation();
    }
  };

  useEffect(() => {
    const fetchContent = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          "https://hallelujahofficial.com/api/godcast/podcasts/content",
          {
            headers: getAuthHeaders(),
            params: { version: "2.0" },
          }
        );
        // console.log(response.data);
        setLibrary(response.data.library);
        setBackgroundMusics(response.data.background_musics);
        setInterludes(response.data.interlude);
        setSounds(response.data.sounds);
      } catch (error) {
        toast.error("Failed to fetch podcasts content");
      } finally {
        setLoading(false);
      }
    };

    fetchContent();
  }, []);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const formatTime = (seconds) => {
    const hrs = String(Math.floor(seconds / 3600)).padStart(2, "0");
    const mins = String(Math.floor((seconds % 3600) / 60)).padStart(2, "0");
    const secs = String(seconds % 60).padStart(2, "0");
    return `${hrs}:${mins}:${secs}`;
  };

  const startRecording = async () => {
    try {
      isCancelledRef.current = false; // Reset the cancellation flag

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream);

      setMediaRecorder(recorder);
      controlAudio("recording");

      recorder.ondataavailable = (e) => {
        if (isCancelledRef.current) return; // Stop processing if canceled

        if (e.data && e.data.size > 0) {
          recordingChunksRef.current = [...recordingChunksRef.current, e.data];
        }
      };

      recorder.start();
      setRecording(true);

      timerRef.current = setInterval(() => {
        if (isCancelledRef.current) return; // Stop timer if canceled
        setTimer((prev) => prev + 1);
      }, 1000);
    } catch (error) {
      console.error("Error starting recording:", error);
      toast.error("Failed to start recording.");
    }
  };

  const stopRecording = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      setRecording(false);
      clearInterval(timerRef.current); // Stop the timer
      controlAudio("inactive");

      // Combine all recorded chunks into a single Blob
      // console.log(recordingChunksRef.current);
      // Delay processing the Blob to allow all chunks to be gathered
      setTimeout(() => {
        const completeBlob = new Blob(recordingChunksRef.current, {
          type: "audio/webm",
        });
        if (completeBlob.size > 0) {
          const url = URL.createObjectURL(completeBlob);
          setAudioURL(url);
          setNewRecording({ ...newRecording, file: completeBlob });
        } else {
          toast.error("Recording failed to create a valid audio file.");
        }
      }, 500); // Adjust the delay as needed
    }
  };

  const cancelRecording = () => {
    isCancelledRef.current = true; // Set the flag to cancel

    // if (mediaRecorder) {
    mediaRecorder.stop();
    // stopRecording();
    setRecording(false);
    clearInterval(timerRef.current); // Stop the timer
    controlAudio("inactive");

    // Clear recording data
    console.log(recordingChunksRef.current);
    recordingChunksRef.current = [];
    console.log(recordingChunksRef.current);
    setAudioURL(null);
    setNewRecording({ ...newRecording, file: null });
    setTimer(0);
    // }
  };

  useEffect(() => {
    return () => clearInterval(timerRef.current);
  }, []);

  const saveRecording = async () => {
    // Check for title length
    if (newRecordingTitle.length > 50) {
      toast.error("Title cannot exceed 50 characters");
      return;
    }

    if (!newRecording.file) {
      toast.error("No recording file found");
      return;
    }

    // Check for name conflicts
    const isDuplicateTitle = segments.some(
      (segment) => segment.name === newRecordingTitle
    );
    if (isDuplicateTitle) {
      toast.error(
        "Recording title already exists. Please choose a different title."
      );
      return;
    }

    const formData = new FormData();
    formData.append("name", newRecordingTitle);
    formData.append("upload_file", newRecording.file);
    // console.log("upload_file",newRecording.file);
    setLoading(true);

    try {
      const response = await axios.post(
        "https://hallelujahofficial.com/api/godcast/segments/recording/add",
        formData,
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
            is_admin: true,
          },
        }
      );

      console.log(loading, loading);
      setAudioURL(null);
      setAudioName("");
      recordingChunksRef.current = []; // Clear previous chunks

      // Fetch the updated segments list after adding the new segment
      const response1 = await axios.get(
        "https://hallelujahofficial.com/api/godcast/segments",
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );
      setSegments(response1.data);
      toast.success("Recording added to Segment successfully");
      setTimer(0);

      // Save to library
      // await handleImport(newRecording.file);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        toast.error("Unauthorized. Please log in again.");
      } else {
        toast.error("Failed to save recording");
      }
    } finally {
      console.log(loading, loading);

      setLoading(false);
      closeNameModal();
      console.log(loading, loading);
    }
  };

  const openConfirmation = (episode) => {
    handleClose();
    setConfirmationIsOpen(true);
  };

  const closeConfirmation = () => {
    setSelectedMusic(null);
    setConfirmationIsOpen(false);
  };

  const openAdd = (record) => {
    handleClose();
    setAddIsOpen(true);
  };

  const closeAdd = () => {
    setAddIsOpen(false);
  };

  const openNameModal = () => {
    setnameIsOpen(true);
    // setNewRecordingTitle("");
  };

  const closeNameModal = () => {
    setnameIsOpen(false);
    clearInterval(timerRef.current);
    setNewRecordingTitle("");
  };

  const openRenameModal = (record) => {
    handleClose();
    setRenameIsOpen(true);
    setNewTitle(selectedMusic.title || ""); // Pre-fill with current title
  };

  const closeRenameModal = () => {
    setRenameIsOpen(false);
    setNewTitle("");
  };

  const handleClick = (event, audio) => {
    setAnchorEl(event.currentTarget);
    setSelectedMusic(audio);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Stop currently playing music when switching tabs
    if (currentlyPlaying) {
      currentlyPlaying.audioRef.current?.pause();
      setCurrentlyPlaying(null);
    }
  }, [activeTab]);

  const handlePlayPause = (musicId, audioRef) => {
    if (
      currentlyPlaying &&
      currentlyPlaying.audioRef &&
      currentlyPlaying.id !== musicId
    ) {
      currentlyPlaying.audioRef.current?.pause();
      currentlyPlaying.audioRef.current.currentTime = 0; // Reset time to start
    }

    if (currentlyPlaying && currentlyPlaying.id === musicId) {
      if (currentlyPlaying.audioRef.current) {
        if (currentlyPlaying.audioRef.current.paused) {
          currentlyPlaying.audioRef.current.play();
        } else {
          currentlyPlaying.audioRef.current.pause();
        }
      }
    } else {
      audioRef.current?.play();
      setCurrentlyPlaying({ id: musicId, audioRef });

      // Listen for the 'ended' event to reset the currently playing state when the song ends
      audioRef.current.onended = () => {
        setCurrentlyPlaying(null);
      };
    }
  };
  // console.log(selectedMusic);
  const handleEdit = async () => {
    // Check for title length
    if (newTitle.length > 50) {
      toast.error("Title cannot exceed 50 characters");
      return;
    }

    // Check for duplicate title in the library
    const isDuplicate = library.some(
      (record) => record.title.toLowerCase() === newTitle.toLowerCase()
    );

    if (isDuplicate) {
      toast.error("Library title already exists");
      return;
    }

    try {
      await axios.post(
        `https://hallelujahofficial.com/api/godcast/library/update/` +
          selectedMusic.id,
        {
          title: newTitle,
          id: selectedMusic.id,
        },
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );
      setLibrary(
        library.map((record) =>
          record.id === selectedMusic.id
            ? { ...record, title: newTitle }
            : record
        )
      );
      toast.success("Library renamed successfully");
    } catch (error) {
      toast.error("Failed to rename library item");
    } finally {
      closeRenameModal();
    }
  };

  const audioProps = {
    audioType: "audio/webm", // Format to be recorded
    status: audioStatus, // Status of the recording
    audioSrc, // Recorded audio source
    timeslice: 1000, // Interval to capture audio
    stopCallback: stopRecording, // Callback when recording stops
    backgroundColor: "white", // Background color of the waveform
    strokeColor: "#ccc", // Waveform stroke color
  };

  const controlAudio = (status) => {
    setAudioStatus(status);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
    handleImport(event.target.files[0]);
  };

  const handleImport = async (file) => {
    if (!file) return;

    const fileName = file.name || newRecordingTitle;
    const formData = new FormData();
    formData.append("audio_file", file);
    formData.append("title", fileName);

    try {
      const response = await axios.post(
        `https://hallelujahofficial.com/api/godcast/library/save`,
        formData,
        {
          headers: {
            secure: apiSecureHeader,
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            is_admin: true,
          },
        }
      );

      const savedItem = response.data; // Assuming the API returns the saved item data
      setLibrary((prevLibrary) => [...prevLibrary, savedItem]);
      toast.success("Added to Library successfully");
    } catch (error) {
      toast.error("Failed adding to Library");
    }
  };

  return (
    <div className="container">
      <Sidebar />
      <main className="content">
        <div className="homeContainer">
          <Toaster />
          <div className="heading">
            <BackButton onClick={() => navigate(-1)} />
            <p style={{ fontSize: "40px" }}>
              {activeTab.charAt(0).toUpperCase() + activeTab.slice(1)}
            </p>
          </div>

          <Tabs
            value={activeTab}
            onChange={handleTabChange}
            variant="fullWidth"
            textColor="primary"
            indicatorColor="primary"
            className="tabs"
          >
            <Tab value="record" label="Record" />
            <Tab value="library" label="Library" />
            <Tab value="interludes" label="Interludes" />
            <Tab value="sounds" label="Sounds" />
          </Tabs>
          {activeTab === "record" && (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              height="60vh"
            >
              <Box display="flex" flexDirection="column" alignItems="center">
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <div
                    className="Mic"
                    style={{
                      background: "#67519F",
                      padding: "20px",
                      borderRadius: "50%",
                      marginBottom: "30px",
                      position: "absolute", // Position absolutely within the container
                      zIndex: setnameIsOpen ? 0 : 10,
                    }}
                  >
                    <MicIcon style={{ fontSize: "4rem", color: "white" }} />
                  </div>
                  <AudioAnalyser {...audioProps} />
                </div>
                {audioSrc && (
                  <audio controls src={audioSrc}>
                    Your browser does not support the audio element.
                  </audio>
                )}
                <Typography variant="h5" color="textSecondary" gutterBottom>
                  {recording ? formatTime(timer) : formatTime(timer)}
                </Typography>
              </Box>
              <Box
                display="flex"
                alignItems="center"
                justifyContent={recording ? "center" : "space-between"}
                mt={4}
                width={"400px"}
                border={"2px solid #eee"}
                borderRadius={"3rem"}
                padding={"10px"}
              >
                {!recording && (
                  <IconButton onClick={cancelRecording}>
                    <CloseIcon fontSize="large" color="secondary" />
                  </IconButton>
                )}
                <IconButton
                  onClick={recording ? stopRecording : startRecording}
                >
                  {recording ? (
                    <PauseIcon fontSize="large" color="primary" />
                  ) : (
                    <PlayArrowIcon fontSize="large" color="primary" />
                  )}
                </IconButton>
                {/* <IconButton onClick={startRecording} disabled={recording}>
                  <MicIcon fontSize="large" color="primary" />
                </IconButton> */}

                {!recording && (
                  <IconButton
                    onClick={() => openNameModal()}
                    disabled={!audioURL}
                  >
                    <CheckIcon fontSize="large" color="primary" />
                  </IconButton>
                )}
              </Box>
            </Box>
          )}
          {activeTab === "library" && (
            <Box>
              {library.length > 0 ? (
                <div
                  className="recordList library"
                  style={{ maxHeight: "400px" }}
                >
                  {library.map((record) => (
                    <RecordItem
                      key={record.id}
                      record={record}
                      handleClick={handleClick}
                      anchorEl={anchorEl}
                      open={open}
                      handleClose={handleClose}
                      handleDelete={() => openConfirmation(record)}
                      handleAdd={() => openAdd(record)}
                      handleEdit={() => openRenameModal(record)}
                      isLibrary={true}
                      onPlayPause={(audioRef) =>
                        handlePlayPause(record.id, audioRef)
                      }
                      currentlyPlaying={currentlyPlaying?.id === record.id}
                    />
                  ))}
                </div>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No recordings found
                </Typography>
              )}
              <div style={{ marginTop: "50px" }}>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    backgroundColor: "#67519F",
                    color: "white",
                    borderRadius: "50px",
                    padding: "8px 24px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textTransform: "none",
                    fontSize: "1rem",
                    width: "300px",
                    margin: "0 auto",
                  }}
                  startIcon={<CloudUploadIcon style={{ color: "white" }} />}
                >
                  Import
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }} // Hide the file input element
                    accept="audio/*"
                    onChange={handleFileChange}
                  />
                </Button>
              </div>
            </Box>
          )}
          {activeTab === "interludes" && (
            <Box>
              {loading ? (
                <Typography variant="body1" color="textSecondary">
                  Loading content...
                </Typography>
              ) : interludes.length > 0 ? (
                <div className="recordList library">
                  {interludes.map((categoryObj, index) => (
                    <div key={index}>
                      <h3 style={{ marginLeft: "20px" }}>
                        {categoryObj.category}
                      </h3>
                      {categoryObj.items.map((record) => (
                        <RecordItem
                          key={record.id}
                          record={record}
                          handleClick={handleClick}
                          anchorEl={anchorEl}
                          open={open}
                          handleClose={handleClose}
                          handleAdd={() => openAdd(record)}
                          onPlayPause={(audioRef) =>
                            handlePlayPause(record.id, audioRef)
                          }
                          currentlyPlaying={currentlyPlaying?.id === record.id}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No content found
                </Typography>
              )}
            </Box>
          )}
          {activeTab === "sounds" && (
            <Box>
              {loading ? (
                <Typography variant="body1" color="textSecondary">
                  Loading content...
                </Typography>
              ) : sounds.length > 0 ? (
                <div className="recordList library">
                  {sounds.map((categoryObj, index) => (
                    <div key={index}>
                      <h3 style={{ marginLeft: "20px" }}>
                        {categoryObj.category}
                      </h3>
                      {categoryObj.items.map((record) => (
                        <RecordItem
                          key={record.id}
                          record={record}
                          handleClick={handleClick}
                          anchorEl={anchorEl}
                          open={open}
                          handleClose={handleClose}
                          handleAdd={() => openAdd(record)}
                          onPlayPause={(audioRef) =>
                            handlePlayPause(record.id, audioRef)
                          }
                          currentlyPlaying={currentlyPlaying?.id === record.id}
                        />
                      ))}
                    </div>
                  ))}
                </div>
              ) : (
                <Typography variant="body1" color="textSecondary">
                  No content found
                </Typography>
              )}
            </Box>
          )}
        </div>
        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={closeConfirmation}
          contentLabel="Confirm Delete"
          style={{
            content: {
              maxHeight: "190px",
              borderRadius: "12px",
            },
          }}
        >
          <img
            src={checked}
            alt=""
            style={{
              width: "48px",
              height: "48px",
              marginBottom: "-35px",
            }}
          />
          <h2>Confirmation</h2>
          <p
            style={{
              textAlign: "center",
              marginTop: "-5px",
              fontSize: "0.85em",
            }}
          >
            Are you sure you want to delete this segment?
          </p>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeConfirmation}
            >
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={handleDelete}
            >
              Yes
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={renameIsOpen}
          onRequestClose={closeRenameModal}
          contentLabel="Rename Segment"
          style={{
            content: {
              maxHeight: "200px",
              borderRadius: "12px",
              padding: "0px 45px 10px 30px",
            },
          }}
        >
          <h2>Rename Segment</h2>
          <input
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
            placeholder="Enter new title"
          />
          <div className="button-group" style={{ marginLeft: "20px" }}>
            <button
              type="button"
              className="sub-can-button"
              onClick={closeRenameModal}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={handleEdit}
            >
              Save
            </button>
          </div>
        </Modal>
        <Modal
          isOpen={nameIsOpen}
          onRequestClose={closeNameModal}
          contentLabel="Name Segment"
          style={{
            content: {
              maxHeight: "221px",
              borderRadius: "12px",
              padding: "20px 45px 30px 30px",
            },
          }}
        >
          {
            <>
              <img
                src={checked}
                alt="Checked Icon"
                style={{
                  width: "48px",
                  height: "48px",
                  marginBottom: "-35px",
                }}
              />
              <h2>Save Recording</h2>
              <input
                     type="text"
                     value={newRecordingTitle}
                     onChange={(e) => setNewRecordingTitle(e.target.value)}
                     placeholder="Enter new title"
                  />
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // Optional: set the height of the container to ensure vertical centering within a specific area
                    minHeight: "100px", // Adjust as needed based on the modal size or context
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <div className="button-group" style={{ marginLeft: "20px" }}>
                  <button
                    type="button"
                    className="sub-can-button"
                    onClick={closeNameModal}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="sub-can-button"
                    onClick={saveRecording}
                  >
                    Save
                  </button>
                </div>
              )}
            </>
          }
        </Modal>
        <Modal
          isOpen={addIsOpen}
          onRequestClose={closeAdd}
          contentLabel="Add to episode"
          style={{
            content: {
              maxHeight: "190px",
              borderRadius: "12px",
            },
          }}
        >
          <img
            src={checked}
            alt=""
            style={{
              width: "48px",
              height: "48px",
              marginBottom: "-35px",
            }}
          />
          <h2>Confirmation</h2>
          <p
            style={{
              textAlign: "center",
              marginTop: "-5px",
              fontSize: "0.85em",
            }}
          >
            Are you sure you want add this to segment?
          </p>
          <div className="button-group">
            <button type="button" className="sub-can-button" onClick={closeAdd}>
              No
            </button>
            <button
              type="submit"
              className="sub-can-button"
              onClick={() => {
                if (activeTab === "library") {
                  handleAddLibrary();
                } else if (activeTab === "interludes") {
                  handleAddInterlude();
                } else if (activeTab === "sounds") {
                  handleAddSound();
                }
              }}
            >
              Yes
            </button>
          </div>
        </Modal>
      </main>
    </div>
  );
};

export default Record;
