import axios from 'axios';

import { apiSecret1, apiSecret2, apiSecureHeader } from './constants';

const isDevelopment = process.env.NODE_ENV === 'development';


export async function getOverviewSummary(type) {
    try {
        // if (isDevelopment) console.log(type);

       
         {
            const response = await axios.get(
                'https://hallelujahofficial.com/api/godcast/overview/summary',
                {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type }
                }
            );
            // if (isDevelopment) console.log([type], response.data);

            return response.data;
        }
    } catch (error) {
        // console.error('Error fetching overview summary:', error);
        throw error;
    }
}

export const getOverviewPerformance = async (type,  range) => {
    try {
        
         {
            const response = await axios.get(
                'https://hallelujahofficial.com/api/godcast/overview/performance',
                {
                    headers: {
                        'secure': apiSecureHeader,
                        'Authorization': `Bearer ${localStorage.getItem('token')}`,
                        'is_admin': true,
                    },
                    params: { type,  range }
                }
            );
            // if (isDevelopment) console.log(response.data);

            return response.data;
        }
    } catch (error) {
        // console.error('Error fetching overview performance:', error);
        throw error;
    }
};

export async function getAudienceSummary(type) {
    try {
        const response = await axios.get(
            'https://hallelujahofficial.com/api/godcast/audience/summary',
            {
                headers: {
                    'secure': apiSecureHeader,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'is_admin': true,
                },
                params: { type }
            }
        );
        // if (isDevelopment) console.log(response.data); // Logging the data instead of the response object
        return response.data;
    } catch (error) {
        // console.error('Error fetching audience summary:', error);
        throw error;
    }
}

export const getAudiencePerformance = async (type,  range) => {
    try {
        const response = await axios.get(
            'https://hallelujahofficial.com/api/godcast/audience/performance',
            {
                headers: {
                    'secure': apiSecureHeader,
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'is_admin': true,
                },
                params: { type,  range }
            }
        );
        // if (isDevelopment) console.log(response.data);

        return response.data;
    } catch (error) {
        // console.error('Error fetching audience performance:', error);
        throw error;
    }
};
